import React, { useState, useRef, useContext } from "react"

import Modal from "src/componentsV2/ui/Modal"
import airmasonIcon from "src/images/logo_airmason.svg"
import {
  Input,
  EmailInput,
  PhoneInput,
  ErrorMessage,
  FormField,
} from "src/componentsV2/InputForm"
import { useFormState } from "src/components/Form"
import { ButtonBlue } from "src/componentsV2/StyledButton"
import PaymentLoading from "src/components/Payment/paymentLoading"
import RadioButton from "src/componentsV2/ui/RadioButton"
import TextArea from "src/componentsV2/ui/TextArea"
import { FormContext } from "src/components/Form"
import { actionTypes } from "src/components/Form/reducer"
import useContactSales from "src/hooks/useContactSales"
import config from "src/config"
import getErrorMsgFromResponse from "src/utils/getErrorMsgFromResponse"

import ContactSalsesModalSuccess from "./ContactSalesModalSuccess"
import { WrapperModal, FormStyled } from "./style"
import moment from "moment"

const formName = "contactSales"

enum Reply_By {
  phone = "phone",
  email = "email",
}
interface ReplyByEmailOrPhoneProps {
  dispatchFn: any
  onChange: any
  value: string
}

const PhoneInputContactSale = Input.withComponent(PhoneInput)

const ReplyByEmailOrPhone: React.FC<ReplyByEmailOrPhoneProps> = (
  props
): any => {
  const { dispatchFn } = props

  const isByPhone = !props.value || props.value == Reply_By.phone

  const handleChange = (value: string) => {
    if (value == Reply_By.email) {
      dispatchFn("phone", "")
      PhoneInputContactSale.validate = () => undefined
    } else {
      PhoneInputContactSale.validate = (value: string) =>
        value ? undefined : "This field is required."
    }
    props.onChange(value)
  }

  return (
    <div className="flex mt-4 mb-6">
      <div>
        <RadioButton
          name="reply-by"
          caption="By Phone"
          className="mr-6"
          captionClassName={isByPhone && `opacity-100`}
          onChange={(value: string) => handleChange(value)}
          checked={isByPhone}
          value={Reply_By.phone}
        />
      </div>
      <div>
        <RadioButton
          name="reply-by"
          caption="By Email"
          captionClassName={!isByPhone && `opacity-100`}
          checked={props.value == Reply_By.email}
          onChange={(value: string) => handleChange(value)}
          value={Reply_By.email}
        />
      </div>
    </div>
  )
}

function gtag_report_conversion(): void {
  if (typeof gtag === "function") {
    gtag("event", "conversion", {
      send_to: config.GA_MEASUREMENT_CONVERSION_ID,
    })
  }
}

const ContactSalesModal = (props: any) => {
  const formState = useFormState(formName)
  const modalRef: any = useRef()
  const [error, setError] = useState("")
  const { dispatch } = useContext<any>(FormContext)
  const { submit: submitContactSales } = useContactSales()

  const submitting = formState && formState.getIn(["meta", "submitting"])
  const dirty = formState && formState.getIn(["meta", "dirty"])
  const isError = formState && !!Object.keys(formState.errors || {}).length
  const initialValues = {
    replyBy: Reply_By.phone,
  }

  //State
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [
    isContactSaleModalSuccessOpen,
    setIsContactSaleModalSuccessOpen,
  ] = useState(false)

  const dispatchFn = (fileName: string, error: string) => {
    dispatch({
      type: actionTypes.FORM_FIELD_ERROR,
      formName: formName,
      fieldName: fileName,
      error,
    })
  }

  const handleContactSalesModalSuccess = () => {
    setIsContactSaleModalSuccessOpen(!isContactSaleModalSuccessOpen)
  }

  const submit = async (values: any) => {
    setError("")
    const lastSalesFormSubmission = window.localStorage.getItem(
      "lastSalesFormSubmission"
    )
    if (
      lastSalesFormSubmission &&
      moment(lastSalesFormSubmission) > moment().subtract(7, "days")
    ) {
      const errorMessage =
        "You cannot submit another request so soon. Please try again later."

      setError(errorMessage)
      return
    }

    const errMsg = "This field is required"

    if (!values.name) {
      dispatchFn("name", errMsg)
      return
    }

    if (!values.email) {
      dispatchFn("email", errMsg)
      return
    }

    if (!values.phone && values.replyBy == Reply_By.phone) {
      dispatchFn("phone", errMsg)
      return
    }

    try {
      setIsFormSubmitted(true)
      await submitContactSales({ variables: { input: values } })
      setIsFormSubmitted(false)

      if (analytics) {
        analytics.identify(values)
        analytics.track("Contact-Sales")
      }

      gtag_report_conversion()
      window.localStorage.setItem(
        "lastSalesFormSubmission",
        new Date().toString()
      )
      setTimeout(() => {
        handleContactSalesModalSuccess()
      }, 400)
    } catch (e) {
      setIsFormSubmitted(false)
      const errMsg = getErrorMsgFromResponse(e)
      setError(errMsg)
    }
  }

  const closeModal = (): void => {
    modalRef.current.close()
  }

  const onClose = () => {
    dispatch({
      type: actionTypes.FORM_SUBMIT_RESET,
      formName,
    })

    dispatchFn("name", "")
    dispatchFn("email", "")
    dispatchFn("phone", "")

    props.onClose && props.onClose()
  }

  return (
    <React.Fragment>
      <Modal
        id="contact-sales-modal"
        {...props}
        ref={modalRef}
        className={`contact-wrapper ${
          !isContactSaleModalSuccessOpen ? "is-bg" : ""
        }`}
        onClose={onClose}
      >
        {isContactSaleModalSuccessOpen ? (
          <ContactSalsesModalSuccess closeModal={closeModal} />
        ) : (
          <WrapperModal className="content-overflow">
            <div className="modal-contact">
              <img src={airmasonIcon} alt="airmason" />
              <div className="modal-title">Contact sales</div>
            </div>
            <FormStyled
              name={formName}
              initialValues={initialValues}
              onSubmit={submit}
              className="text-left"
            >
              <FormField
                name="name"
                title="Your Name"
                required-sign="*"
                placeholder=""
                component={Input}
                className="contact-sales-title"
              />
              <FormField
                name="email"
                title="Your company email address"
                required-sign="*"
                placeholder=""
                formName={formName}
                component={EmailInput}
                type="email"
                className="contact-sales-title"
              />
              <FormField
                name="phone"
                title="Your phone number"
                required-sign="*"
                placeholder=""
                component={PhoneInputContactSale}
                className="contact-sales-title"
              />

              <FormField
                name="replyBy"
                title="How do you want us to reply?"
                component={ReplyByEmailOrPhone}
                className="contact-sales-title"
                dispatchFn={dispatchFn}
              />

              <FormField
                name="message"
                title="Your message"
                description="(optional)"
                component={TextArea}
                className="contact-sales-title"
              />

              <div className="w-full">
                <ErrorMessage>{error}</ErrorMessage>
                <div className="flex justify-between items-center">
                  <div className="cancel" onClick={closeModal}>
                    Cancel
                  </div>
                  <ButtonBlue
                    disabled={(submitting || isError) && dirty}
                    type="submit"
                  >
                    {!isFormSubmitted ? (
                      `Send message `
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "0 1rem",
                        }}
                      >
                        <span className="mr-2">Loading...</span>
                        <PaymentLoading />
                      </div>
                    )}
                  </ButtonBlue>
                </div>
              </div>
            </FormStyled>
          </WrapperModal>
        )}
      </Modal>
    </React.Fragment>
  )
}

export default ContactSalesModal
