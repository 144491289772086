import styled from "styled-components"

export const ModalSuccessStyled = styled.div`
  position: relative;
  text-align: center;
  padding: 57px 0px 126px;
  img {
    margin-bottom: 45px;
  }
  .title {
    color: #0f1722;
    font-family: "Calluna-Regular";
    font-size: 30px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    .description {
      font-size: 15px;
      color: #676b87;
      font-family: "OpenSans-Regular";
    }
  }
  .button {
    min-width: 130px;
    margin-top: 30px;
  }
  @media screen and (max-height: 770px) {
    padding: 30px 0px;
  }
  @media screen and (max-height: 640px) {
    padding: 0px;
  }
  @media screen and (max-height: 350px) {
    img {
      margin-bottom: 20px;
    }
    .button {
      margin-top: 20px;
    }
  }
`
