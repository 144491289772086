/* eslint-disable */
import React from "react"
import styled from "styled-components"

import WidthWrapper from "src/components/widthWrapper"
import HeadingSmall from "src/components/headingSmall"
import TestimonialSlider from "src/components/TestimonialSlider"
import teamPicturePath from "src/images/teamPicture.png"
import tw from "tailwind.macro"

import heartsIconPath from "src/images/heartsIcon.svg"

import testimonialSliderMobileBackgroundPath from "src/images/shapes/testimonialSliderMobileBackground.svg"

const Section = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-bottom: 130px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 992px) {
    margin-top: 71px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  // our mobile max width to keep things centered
  max-width: 451px;
  width: 100%;
  position: relative;
  overflox-x: hidden;
`

const TeamPicture = styled.img`
  @media only screen and (min-width: 992px) {
    max-width: 352px;
  }
  @media only screen and (max-width: 992px) {
    order: 1;
    margin-bottom: -27px;
    top: -55px;
    position: relative;
  }
`

// TODO: mobile background alignment
const BackgroundShapeDesktop = styled.img`
  transform: scale(1.3);
  position: absolute;
  z-index: -2;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`

const BackgroundShapeMobile = styled.img`
  position: absolute;
  z-index: -2;
  vertical-align: top;
  height: 489px;
  width: 465px;
  transform: scale(1.1);
  margin-left: -109px;

  max-width: none;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`

const Testimonialwrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (min-width: 992px) {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 992px) {
    max-width: 451px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: start;
  max-width: 451px;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    order: 2;
    margin-bottom: 88px;
  }
  @media only screen and (min-width: 992px) {
    margin-bottom: 44px;
    margin-left: 53px;
  }
`

const IconText = styled(HeadingSmall)`
  font-family: Lato;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #0f1722;
  @media only screen and (min-width: 992px) {
    max-width: 240px;
  }
`

const Icon = styled.img`
  margin-right: 21px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 27px;
  }
`

const Span = styled.div`
  text-align: center;
  width: 100%;
  height: auto;
  margin: 5rem auto;
  padding: 1.5rem;
  line-height: 32px;
  font-size: 24px;
  box-shadow: 1px 1px 5px gray;
  text-align: center;
  border-radius: 5px;
  > :last-child {
    margin-top: 0.8rem;
    line-height: 18px;
    font-size: 18px;
    color: grey;
  }

  @media only screen and (max-width: 450px) {
    line-height: 28px;
    margin-bottom: 8rem;
    > :last-child {
      line-height: 18px;
    }
  }
`
const SubSpan = styled.div`
  display:block;
  @media only screen and (max-width: 450px) {
    display:none;

`

const SocialProof = ({ withSpan, ...props }) => (
  <Section>
    <WidthWrapper>
      {withSpan && (
        <Span>
          Have more than <strong>200 employees</strong>? Give us a call:
          <strong> +1 (646) 918-8673 Ext. 005</strong>
          <SubSpan>
            You may qualify for AirMason Enterprise, which gets you a dedicated
            account manager, custom integrations, creative support and more.
          </SubSpan>
        </Span>
      )}
      <Row>
        <Column>
          <IconWrapper>
            <Icon src={heartsIconPath} alt="heart icon" />
            <IconText>Why global HR professionals choose AirMason.</IconText>
          </IconWrapper>
          <TeamPicture src={teamPicturePath} />
        </Column>
        <Column>
          <Testimonialwrapper>
            <TestimonialSlider />
          </Testimonialwrapper>
        </Column>
      </Row>
    </WidthWrapper>
  </Section>
)

export default SocialProof
