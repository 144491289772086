/* eslint-disable */
import React from "react"
import styled from "styled-components"

import HeadingSmall from "../../components/headingSmall"
import HideOnDesktop from "../../components/hideOnDesktop"
import HideOnMobile from "../../components/hideOnMobile"
import TextStandard from "../../components/textStandard"
import WidthWrapper from "../../components/widthWrapper"
import editIconPath from "../../images/editIcon.svg"
import featureTennisDesktopBackgroundPath from "../../images/shapes/featureTennisDesktopBackground.svg"
import featureTennisDesktopBackgroundPathGrey from "../../images/shapes/featureTennisDesktopBackgroundGrey.svg"
import shareIconPath from "../../images/shareIcon.png"
import wandIconPath from "../../images/wandIcon.svg"
import feature1VideoPath from "../../videos/feature1.mp4"
import feature2VideoPath from "../../videos/feature2.mp4"
import feature3VideoPath from "../../videos/feature3.mp4"
import feature3DesktopVideoPath from "../../videos/feature3Desktop.mp4"
import feature1PosterPath from "../../videos/posters/feature1.png"
import feature2PosterPath from "../../videos/posters/feature2.png"
import feature3PosterPath from "../../videos/posters/feature3.png"
import feature3DesktopPosterPath from "../../videos/posters/feature3Desktop.png"

const Section = styled.div`
  overflow: hidden;

  z-index: 2;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  overflow-x: hidden;
  position: relative;

  padding-bottom: 60px;

  @media only screen and (min-width: 992px) {
    padding-bottom: 0;
  }
`

const BackgroundDesktop = styled.img`
  position: absolute;
  z-index: -999;
  margin-left: 450px;
  margin-top: 100px;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`

const BackgroundDesktopLeft = styled.img`
  width: 700px;
  position: absolute;
  left: 0;
  z-index: -999;
  // margin-right: -50%;
  margin-top: 100px;

  @media only screen and (max-width: 1600px) and (min-width: 993px) {
    left: -25%;
  }

  @media only screen and (max-width: 992px) {
    display: none;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const useSyncVideo = (videoSources = {}) => {
  const videoCount = Object.keys(videoSources).length
  const [loadedCount, setLoadedCount] = React.useState(1)

  const videos = Object.values(videoSources).reduce(
    (acc, src) => ({
      ...acc,
      [src]: {
        ref: React.useRef(),
        src,
      },
    }),
    {}
  )

  React.useEffect(() => {
    Object.values(videos).forEach(({ ref, src }) => {
      if (!ref.current) {
        console.warn(`Could not access video ref: ${src}`)
      } else {
        ref.current.addEventListener("canplay", () => {
          setLoadedCount(loadedCount + 1)
        })
      }
    })
  }, [])

  React.useEffect(() => {
    if (loadedCount === videoCount) {
      Object.values(videos).forEach(async ({ ref }) => {
        try {
          await ref.current.play()
        } catch (e) {
          console.log("Cannot play", e)
        }
      })
    }
  }, [loadedCount])

  return videos
}

const FeatureVideo = styled.video`
  @media only screen and (min-width: 992px) {
    max-height: 400px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    height: auto;
    order: 1;
  }
`

const FeatureHeading = styled(HeadingSmall)`
  @media only screen and (min-width: 992px) {
    max-width: 222px;
  }
  margin-bottom: 9px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
`

const Icon = styled.img`
  max-width: 160px;
  margin-bottom: 15px;
`

const FeatureText = styled(TextStandard)`
  @media only screen and (min-width: 992px) {
    max-width: 222px;
  }
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #8c9bb3;
`

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: 992px) {
    order: 2;
    margin-top: 38px;
  }
`

const FeaturesHeading = styled.h3`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 2.66667px;
  color: #a0adc1;
  @media only screen and (min-width: 992px) {
    margin: 143px 0 47px 0;
  }
  @media only screen and (max-width: 992px) {
    margin: 81px 0 26px 0;
  }
  text-align: center;
`

const RowMargin = styled.div`
  @media only screen and (min-width: 992px) {
    margin-top: 153px;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 50px;
  }
`

const FeatureHeading1 = styled(FeatureHeading)`
  @media only screen and (min-width: 992px) {
    max-width: 240px;
  }
`

const FeatureHeading2 = styled(FeatureHeading)`
  @media only screen and (min-width: 992px) {
    max-width: 233px;
  }
`

const FeatureHeading3 = styled(FeatureHeading)`
  @media only screen and (min-width: 992px) {
    max-width: 251px;
  }
`

const Feature1 = styled(FeatureWrapper)`
  @media only screen and (min-width: 992px) {
    margin-right: 94px;
  }
`

const Feature2 = styled(FeatureWrapper)`
  @media only screen and (min-width: 992px) {
    margin-left: 90px;
  }
`

const Feature3 = styled(FeatureWrapper)`
  @media only screen and (min-width: 992px) {
    min-width: 260px;
    align-self: center;
    margin-right: 65px;
  }
`

const FeatureTennis = () => {
  const videos = useSyncVideo({ feature3DesktopVideoPath, feature3VideoPath })

  return (
    <Section>
      <WidthWrapper>
        <BackgroundDesktop src={featureTennisDesktopBackgroundPath} />
        <FeaturesHeading>FEATURES</FeaturesHeading>
        <Row>
          <Feature1>
            <Icon src={wandIconPath} alt="icon for Handbook Wizard" />
            <FeatureHeading1>
              Easy-to-use employee handbook builder
            </FeatureHeading1>
            <FeatureText>
              It's never been easier to create an employee handbook online. Our
              industry-specific templates mean 90% of the work is already done.
            </FeatureText>
          </Feature1>
          <FeatureVideo
            src={feature1VideoPath}
            poster={feature1PosterPath}
            preload="none"
            autoPlay={false}
            muted
            loop
            controls
          >
            <img src={feature1PosterPath} alt="employee handbook software" />
          </FeatureVideo>
        </Row>

        <BackgroundDesktopLeft src={featureTennisDesktopBackgroundPathGrey} />

        <RowMargin>
          <Row>
            <FeatureVideo
              src={feature2VideoPath}
              poster={feature2PosterPath}
              preload="none"
              autoPlay={false}
              muted
              loop
              controls
            >
              <img src={feature2PosterPath} alt="employee handbook software" />
            </FeatureVideo>
            <Feature2>
              <Icon src={editIconPath} alt="icon for Handbook Wizard" />
              <FeatureHeading2>
                Customize your handbook with the click of a button
              </FeatureHeading2>
              <FeatureText>
                Add photos, videos and link to external documents on the fly.
                With 60+ fonts and colour-matching capabilities, your handbook
                will be a direct extension of your brand.
              </FeatureText>
            </Feature2>
          </Row>
        </RowMargin>

        <RowMargin>
          <Row>
            <Feature3>
              <Icon src={shareIconPath} alt="icon for Handbook Wizard" />
              <FeatureHeading3>
                Sharing your handbook just got easier
              </FeatureHeading3>
              <FeatureText>
                No more stops at the print shop. Now you can share your employee
                handbook with a simple URL — accessible anytime, anywhere.
              </FeatureText>
            </Feature3>
            <HideOnDesktop>
              <FeatureVideo
                {...videos[feature3DesktopVideoPath]}
                poster={feature3DesktopPosterPath}
                preload="none"
                autoPlay={false}
                muted
                loop
                controls
              >
                <img
                  src={feature3DesktopPosterPath}
                  alt="employee handbook software"
                />
              </FeatureVideo>
            </HideOnDesktop>
            <HideOnMobile style={{ display: "flex" }}>
              <FeatureVideo
                style={{
                  alignSelf: "flex-end",
                  position: "relative",
                  maxHeight: 600,
                  height: 600,
                  bottom: -100,
                }}
                {...videos[feature3VideoPath]}
                poster={feature3PosterPath}
                preload="none"
                autoPlay={false}
                muted
                loop
                controls
              >
                <img
                  src={feature3PosterPath}
                  alt="employee handbook software"
                />
              </FeatureVideo>

              <FeatureVideo
                style={{
                  zIndex: 100,
                  position: "relative",
                  right: 100,
                  bottom: -5,
                  alignSelf: "flex-end",
                  borderRadius: 4,
                  boxShadow: "-4px 5px 15px rgba(0,0,0, 0.3)",
                }}
                {...videos[feature3DesktopVideoPath]}
                poster={feature3DesktopPosterPath}
                preload="none"
                autoPlay={false}
                muted
                loop
                controls
              >
                <img
                  src={feature3DesktopPosterPath}
                  alt="employee handbook software"
                />
              </FeatureVideo>
            </HideOnMobile>
          </Row>
        </RowMargin>
      </WidthWrapper>
    </Section>
  )
}

export default FeatureTennis
