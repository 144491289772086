/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-confusing-arrow */

import React, { useState } from "react"
import styled from "styled-components"
import plusIconPath from "../images/plusIcon.svg"

const Column = styled.div`
  display: flex;
  flex-direction: column;
  // our mobile max width to keep things centered
  width: 100%;
  max-width: 581px;
`

const ColumnWrapper = styled(Column)`
  margin-top: 34px;
  @media only screen and (min-width: 992px) {
    margin-top: 25px;
  }
`

const ColumnItem = styled(Column)`
  @media only screen and (max-width: 992px) {
    margin-top: 22px;
  }
  overflow: hidden;
`

const ColumnAnswer = styled(Column)`
  @media only screen and (min-width: 992px) {
    padding-left: 21px;
  }
  @keyframes in {
    0% {
      transform: translateY(-20%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  @keyframes out {
    0% {
      transform: translateY(0%);
    }
    99% {
      transform: translateY(-20%);
    }
    100% {
      height: 0px;
      display: none;
    }
  }
  ${props =>
    props.show
      ? `
      opacity: 1;
      transition: height 0.5s ease-in;
      transition: opacity 0.5s ease-in;
      animation-name: in;
      animation-duration: 0.5s;
    `
      : `
      opacity: 0;
      transition: opacity 0.5s ease-in;
      animation-name: out;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    `};
`

const ItemQuestion = styled.div`
  font-family: Lato;
  font-size: 16px;
  line-height: 1.4;
  color: #0f1722;
  @media only screen and (max-width: 992px) {
    margin-bottom: 18px;
  }
`

const ItemAnswer = styled.div`
  font-family: Lato;
  font-size: 16px;
  line-height: 22px;
  color: #8c9bb3;
  margin-bottom: 30px;
`

const ClickableWrapper = styled.div`
  user-select: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
  @media only screen and (min-width: 992px) {
    border-radius: 30px;

    align-items: center;
    margin-bottom: 22px;
    padding: 0px 31px 0px 31px;
    ${props =>
      props.active
        ? `
        padding: 21px 31px 21px 31px;
    
    `
        : ""}
    background-color: ${props => (props.active ? "#EFF7FF" : "none")};
    transition: background-color 0.5s ease-in;
  }
`

const PlusIcon = styled.img`
  ${props =>
    props.rotate
      ? `
      transform: rotate(0deg) scale(0.9);
      transition: transform .5s ease-out;
    `
      : `
      transform: rotate(90deg) scale(1);
      transition: transform .5s ease-in;
    `};
`

const Hr = styled.div`
  border: 1px solid #ececec;
  margin-bottom: 30px;
  height: 1px;
  width: 100%;
`

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <ColumnItem key={question}>
      <ClickableWrapper onClick={onClick} active={isOpen}>
        <ItemQuestion>{question}</ItemQuestion>
        <PlusIcon src={plusIconPath} alt="" rotate={isOpen} />
      </ClickableWrapper>
      <ColumnAnswer show={isOpen} key={question}>
        <ItemAnswer>{answer}</ItemAnswer>

        <Hr />
      </ColumnAnswer>
    </ColumnItem>
  )
}

const accordion = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState()

  return (
    <ColumnWrapper>
      {items.map((item, index) => (
        <AccordionItem
          onClick={() =>
            currentIndex === index
              ? setCurrentIndex(undefined)
              : setCurrentIndex(index)
          }
          isOpen={currentIndex === index}
          key={item.question}
          {...item}
        />
      ))}
    </ColumnWrapper>
  )
}

export default accordion
