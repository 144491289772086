import React from "react"
import styled from "styled-components"
import css from "@emotion/css"
import { FiCalendar } from "react-icons/fi"

const StyledDemoButton = styled.button`
  height: 35px;
  width: 155px;
  border-radius: 17.5px;
  background-color: #fb8b42;
  color: #ffffff;
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  @media (min-width: 768px) {
    font-size: 16px;
  }
`

export const BookDemoButton = (props: any) => {
  return (
    <StyledDemoButton {...props}>
      <FiCalendar
        size={20}
        css={css`
          margin-right: 15px;
        `}
      />
      {props.title ? props.title : "Book a demo"}
    </StyledDemoButton>
  )
}

export const ButtonTransparent = styled.button`
  font-weight: bold;
  padding: 0px 30px;
  background-color: transparent;
  color: #0f1722;
  min-width: 110px;
  transition: all 0.3s ease-out;
  font-family: Lato;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  text-align: center;
  border: 1.5px solid #0f1722;
  border-radius: 30px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 3px 9px -2px rgba(0, 0, 0, 0.15);
    border-color: #777b9b;
  }

  :focus {
    outline: 0;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }
`
