/*  eslint-disable */

import { gql, useMutation } from "src/apolloClient"

const useContactSales = () => {
  const [submit] = useMutation(gql`
    mutation submit($input: ContactSalesInput) {
      submit(input: $input) @rest(path: "/contact/sales", method: "POST")
    }
  `)

  return { submit }
}

export default useContactSales
