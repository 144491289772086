import styled from "styled-components"

import Form from "src/components/Form"

export const WrapperModal = styled.div`
  .modal-contact {
    display: flex;
    .modal-title {
      color: #0e282d;
      font-size: 20px;
      line-height: 30px;
      margin-left: 15px;
    }
  }
  .cancel {
    color: #0e282d;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    cursor: pointer;
  }
`

export const FormStyled = styled(Form)`
  margin-top: 31px;
`
