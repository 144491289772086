/* eslint-disable */
import React from "react"
import tw from "tailwind.macro"
import styled from "styled-components"

import HeadingBig from "../../components/headingBig"
import HeadingSmall from "../../components/headingSmall"
import WidthWrapper from "../../components/widthWrapper"

import openBookIconPath from "../../images/icons/bookopen.svg"
import customiseIconPath from "../../images/icons/customise.svg"
import notebookIconPath from "../../images/icons/notebook.svg"
import womenIconPath from "../../images/icons/woman.svg"

import { ButtonBlue } from "../../components/StyledButton"

const Section = styled.div`
  @media only screen and (min-width: 992px) {
    padding-top: 90px;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 72px;
  }
`

const HeadingBigStyled = styled(HeadingBig)`
  max-width: 600px;
  font-size: 24px;
  margin-bottom: 25px;
`

const HeadingStyled = styled.h2`
  ${tw`font-sans text-3xl `};
  @media only screen and (max-width: 992px) {
    font-size: 24px;
    line-height: 40px;
  }
  width: 100%;
  font-weight: 300;
  font-size: 30px;
  color: #0f1722;
  // @media only screen and (min-width: 992px) {
  //   margin-bottom: 55px;
  // }
`

const ButtonBlueSmall = styled(ButtonBlue)`
  display: none;
  @media only screen and (max-width: 450px) {
    display: block;
    margin: 1rem auto;
  }
`
const ButtonBlueLarge = styled(ButtonBlue)`
  display: block;
  margin: 3rem auto 0 auto;
  @media only screen and (max-width: 450px) {
    display: none;
  }
`

const Feature = styled.div`
  ${tw`my-8 px-8 overflow-hidden md:w-1/4`}
  display: flex;
  align-items: start;
  flex-direction: column;
  /* margin-right: 60px; */

  @media only screen and (max-width: 992px) {
    margin-top: 39px;
  }
`

const FeatureHeading = styled(HeadingSmall)`
  font-family: Lato;
  font-weight: bold;

  margin-bottom: 11px;
  color: #0f1722;
`

const Icon = styled.img`
  margin-bottom: 20px;
  height: 78px;
  object-fit: scale-down;
`

const FeatureText = styled.p`
  font-family: Lato;
  font-size: 16px;
  line-height: 22px;
  color: #8c9bb3;
`

// const IframeContainer = styled.div`
//    {
//     margin: 1rem auto;
//     align-content: center;
//     justify-content: center;
//     border: 1px 1px 5px 5px grey;
//     @media only screen and (max-width: 450px) {
//       display: none;
//     }
//   }
// `
const MainFeatures = () => (
  <Section>
    <WidthWrapper>
      <HeadingBigStyled>
        AirMason's online employee handbook software makes it easy to create,
        distribute, track and update digital employee handbooks — anytime,
        anywhere.
      </HeadingBigStyled>

      <HeadingStyled className="flex justify-center my-8">
        How to Create an Employee Handbook
      </HeadingStyled>
      <div className="flex flex-wrap -ml-8 overflow-hidden">
        {/* <div className="flex flex-col lg:flex-row justify-between"> */}
        <Feature>
          <Icon src={openBookIconPath} />
          <FeatureHeading>1. Create</FeatureHeading>
          <FeatureText>
            You answer some questions and our employee handbook builder
            generates your template. Easy, right?
          </FeatureText>
        </Feature>
        <Feature>
          <Icon src={customiseIconPath} />
          <FeatureHeading>2. Customise</FeatureHeading>
          <FeatureText>
            Our user-friendly employee handbook creator helps you fine-tune
            design and content to perfectly reflect your brand.
          </FeatureText>
        </Feature>
        <Feature>
          <Icon src={notebookIconPath} />
          <FeatureHeading>3. Publish</FeatureHeading>
          <FeatureText>
            Publish and share your handbook as a mobile-friendly webpage. Want
            hard copies? Export-to-PDF in the click of a button.
          </FeatureText>
        </Feature>
        <Feature>
          <Icon src={womenIconPath} />
          <FeatureHeading>4. Manage</FeatureHeading>
          <FeatureText>
            Track which employees have digitally signed their handbook and
            remind those who haven&apos;t. Update your design or content
            anytime!
          </FeatureText>
        </Feature>
      </div>
      {/* <HeadingBigStyled>See the digital handbook in action</HeadingBigStyled>
      <IframeContainer className="flex flex-wrap overflow-hidden">
        <iframe
          src="https://app.airmason.com/books/sterlingsample"
          width="100%"
          height="600"
        />
      </IframeContainer>
      <a
        style={{ textDecoration: "none" }}
        href="https://app.airmason.com/books/sterlingsample"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonBlueLarge>See Handbook in Full Screen</ButtonBlueLarge>
      </a>
      <a
        style={{ textDecoration: "none" }}
        href="https://app.airmason.com/books/sterlingsample"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ButtonBlueSmall>View Handbook </ButtonBlueSmall>
      </a> */}
    </WidthWrapper>
  </Section>
)

export default MainFeatures
