import { navigate } from "gatsby"
import config from "src/config"

export const switchPage = {
  bookDemo: () => {
    window && window.open(config.CALENDLY.ENTERPRISE, "_blank")
  },
  plans: () => {
    navigate("/plans")
  },
  login: () => {
    navigate("/login")
  },
}
