import styled, { StyledComponent } from "@emotion/styled"
import bg_modal from "src/images/bg_modal.png"

const ModalStyle: StyledComponent<any, any, any> & any = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  width: 550px;
  margin: 40px auto;
  visibility: hidden;
  opacity: 0;

  &.fade-in {
    transition: opacity linear 0.15s;
    visibility: visible;
    opacity: 1;
  }

  &.fade-out {
    transition: visibility 0s linear 300ms, opacity 300ms;
    visibility: hidden;
    opacity: 0;
  }

  .background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }

  .box-dialog {
    z-index: 1050;
    width: 100%;
    padding: 50px 40px 40px;
    background-color: #fefefe;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    position: relative;
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  &.contact-wrapper .box-dialog {
    max-width: 450px;
  }

  &.contact-wrapper.is-bg .box-dialog {
    background-image: url("${bg_modal}");
    background-repeat: no-repeat;
    background-size: auto;
    background-position-x: 13%;
    background-position-y: 350px;
  }
  .content-overflow {
    @media screen and (max-height: 920px) {
      max-height: 670px;
      overflow-y: auto;
      padding-right: 7px;
      padding-bottom: 7px;
      margin-right: -15px;
    }
    @media screen and (max-height: 770px) {
      max-height: 590px;
    }
    @media screen and (max-height: 682px) {
      max-height: 440px;
    }
    @media screen and (max-height: 635px) {
      max-height: 430px;
    }
    @media screen and (max-height: 600px) {
      max-height: 360px;
    }
    @media screen and (max-height: 500px) {
      max-height: 250px;
    }
    @media screen and (max-height: 400px) {
      max-height: 200px;
    }

    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: #90a4ae;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #90a4ae;
    }
  }

  @media screen and (max-width: 500px) {
    width: 430px;
  }

  @media screen and (max-width: 415px) {
    width: 350px;
  }

  @media screen and (max-width: 360px) {
    width: 300px;
  }
`

export default ModalStyle
