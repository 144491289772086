import React from "react"

import { ModalSuccessStyled } from "./style"
import sendIcon from "src/componentsV2/images/icons/send_icon.svg"
import { ButtonBlue } from "src/componentsV2/StyledButton"

const ContactSalesModalSuccess = (props: any) => {
  const { closeModal } = props

  return (
    <ModalSuccessStyled>
      <img src={sendIcon} />
      <div className="title">
        Your message was sent.
        <div className="description">
          Our sales team will respond as soon as possible.
        </div>
      </div>

      <ButtonBlue className="button" onClick={closeModal}>
        Go back
      </ButtonBlue>
    </ModalSuccessStyled>
  )
}

export default ContactSalesModalSuccess
