import styled from "styled-components"
import tw from "tailwind.macro"

export default styled.h1`
  ${tw`font-sans text-3xl font-bold`};
  @media only screen and (max-width: 992px) {
    font-size: 24px;
    line-height: 29px;
  }
  color: #0f1722;
`
