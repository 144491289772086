/*  eslint-disable */
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import blueRectanglePath from "src/images/blueRectangle.svg"
import * as R from "ramda"
import tw from "tailwind.macro"
import { useSwipeable } from "react-swipeable"

const testimonials = [
  {
    text:
      "When we saw AirMason, we knew it was something we needed. It’s helped with employee onboarding more than words can express.",
    name: "TopHat",
    logoPath: require("./images/tophat.png"),
  },
  {
    text:
      "We needed a handbook that was captivating and engaging—that’s why we chose AirMason.",
    name: "BNA",
    logoPath: require("./images/bna.png"),
  },
  {
    name: "Cladwell",
    text:
      "Whenever we’re debating what direction to go in, we reference our AirMason handbook because it encompasses who we truly are.",
    logoPath: require("./images/cladwell.png"),
  },
  {
    name: "FlipGroup",
    text:
      "AirMason has allowed us to create a dynamic resource that evolves as we do. We’re proud to share it with new hires and consult it whenever making significant decisions.",
    logoPath: require("./images/flip.png"),
  },
  {
    name: "Shield",
    text:
      "Our entire experience with AirMason has been incredible. When it came to customization, they were with us every step of the way. The result? A handbook that’s identical to our branding.",
    logoPath: require("./images/shield.png"),
  },
]

const Container = styled.div``

const TestimonialContainer = styled.div`
  ${tw`absolute h-full flex justify-center select-none`}


  @media (min-width: ${({ theme, forceMobile }) =>
    forceMobile ? "" : theme.screens.md}) {
    ${tw`p-16 py-20 mx`}
  }
  top: 0;
  overflow: hidden;
  z-index: 1;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  // height:250px;

  @keyframes slide {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation-name: slide;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-duration: 1.2s;
`

const Text = styled.div`
  font-family: Lato;
  font-size: 20px;
  line-height: 25px;
  color: #01a7ff;
`

const HR = styled.div`
  border: 1px solid #c5dbe6;
  width: 95px;
  height: 2px;
  margin-bottom: 16px;
`

const Name = styled.div`
  font-family: Lato;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #0f1722;
`

const Position = styled.div`
  font-family: Lato;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
`

const Row = styled.div`
  ${tw`pt-16`}
  position: absolute;
  bottom: 0;
  @media (min-width: ${({ theme, forceMobile }) =>
      forceMobile ? "" : theme.screens.md}) {
    ${tw`pt-16`}
    bottom: -65px;
  }

  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
`

const CompanyLogo = styled.img`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  display: none;
  animation-name: fadeIn;
  animation-timing-function: cubic-bezier(0, 0, 1, 1);
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-duration: 1.2s;
  max-width: 100px;
  height: auto;

  @media (min-width: ${({ theme, forceMobile }) =>
      forceMobile ? "" : theme.screens.md}) {
    display: inline-block;
  }
`

const Dot = styled.div`
  border: 2px solid #01A7FF;
  border-radius: 50%;
  background: ${props => (props.filled ? "#01A7FF;" : "none;")}
  height: 16px;
  width: 16px;
  transition: background 0.4s cubic-bezier(0,0,1,1);
  margin-right: 18px;
  @media only screen and (max-width: 992px) {
    margin-right: 14px;
  }
`

const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-left: auto;
`

const Dots = ({ count, active, setActive }) => (
  <DotsContainer>
    {R.times(index => (
      <Dot
        key={index}
        onClick={e => {
          e.stopPropagation()
          setActive(index, e)
        }}
        filled={active === index}
      />
    ))(count)}
  </DotsContainer>
)

const Testimonial = ({ text, name, position, ...props }) => (
  <TestimonialContainer {...props}>
    <Text>"{text}"</Text>
    <HR />
    {/* <Name>{name}</Name> */}
    <Position>{name}</Position>
  </TestimonialContainer>
)

const BackgroundImage = styled.img`
  ${tw`invisible w-full h-full z-0 object-fit`}


  @media (min-width: ${({ theme, forceMobile }) =>
    forceMobile ? "" : theme.screens.md}) {
    ${tw`visible`}
    transform: scale(1.2);
  }
`

function TestimonialSlider({ forceMobile }) {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [lastClick, setLastClick] = useState()
  const testimonial = testimonials[currentSlide]

  const handlers = useSwipeable({
    trackMouse: true,
    onSwipedRight: e =>
      setCurrentSlide((currentSlide + 1) % testimonials.length),
    onSwipedLeft: () =>
      setCurrentSlide(Math.abs((currentSlide - 1) % testimonials.length)),
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((currentSlide + 1) % testimonials.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [lastClick])

  return (
    <Container
      onClick={e => {
        setCurrentSlide((currentSlide + 1) % testimonials.length)
        setLastClick(e.timeStamp)
      }}
    >
      <div {...handlers} className="relative">
        <BackgroundImage forceMobile={forceMobile} src={blueRectanglePath} />
        <Testimonial
          forceMobile={forceMobile}
          key={testimonial.logoPath}
          {...testimonial}
        />
        <Row forceMobile={forceMobile}>
          <CompanyLogo
            forceMobile={forceMobile}
            key={testimonial.logoPath}
            src={testimonial.logoPath}
          />
          <Dots
            count={testimonials.length}
            active={currentSlide}
            setActive={(slide, e) => {
              setCurrentSlide(slide)
              setLastClick(e.timeStamp)
            }}
          />
        </Row>
      </div>
    </Container>
  )
}

export default TestimonialSlider
