import { Link } from "gatsby"
/* eslint-disable */
import React from "react"
import useCurrentUser from "src/hooks/useCurrentUser"
import menuIcon from "src/images/burger.svg"
import logo from "src/images/logos/logo.svg"
import styled from "styled-components"
import tw from "tailwind.macro"
import { FaBars } from "react-icons/fa" //black menuIcon
import { getWindowPath } from "src/utils/windowPath"

import Button from "../button.js"
import LoginButton from "../LoginButton"
import config from "../../config"

const MenuIcon = styled.img`
  transition: transform 0.2s ease-in-out;
`

MenuIcon.defaultProps = {
  src: menuIcon,
}

export const Logo = () => <img src={logo} alt="AirMason Logo" />
export const LogoDark = () => (
  <img src={require("src/images/logoBlue.svg")} alt="AirMason Logo" />
)

const ButtonBlue = styled(Button)`
  white-space: nowrap;
  font-weight: bold;
  background: #1283ff;
  color: white;
  margin-left: 15px;
  padding: 10px 25px;
`

const ButtonWhite = styled(Button)`
  white-space: nowrap;
  font-weight: bold;
  background: white;
  color: #00a1ff;
  margin-left: 15px;
  padding: 10px 25px;
`

const UserCTA = () => {
  const { user } = useCurrentUser()
  const pathname = getWindowPath()

  if (user && user.active) {
    return (
      <a href={`${config.HANDBOOK_HOST}/handbooks?to=dashboard`}>
        <ButtonBlue>Go to dashboard</ButtonBlue>
      </a>
    )
  }

  if (user) {
    return (
      <Link to="/plans">
        <ButtonBlue>Continue</ButtonBlue>
      </Link>
    )
  }

  if (pathname === "/enterprise") {
    return (
      <a
        href={config.CALENDLY.ENTERPRISE}
        target="_blank"
      >
        <ButtonWhite>Book a demo</ButtonWhite>
      </a>
    )
  }

  return (
    <Link to="/plans">
      <ButtonBlue>
        {pathname === "/contact" ? "Try it free" : "Get Started"}
      </ButtonBlue>
    </Link>
  )
}

const UserNav = () => {
  const pathname = getWindowPath()
  const { user } = useCurrentUser()

  return (
    <Nav.CTA>
      {pathname !== "/enterprise" && <LoginButton />}
      <UserCTA />
    </Nav.CTA>
  )
}

const MenuText = styled.div`
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: ${({ dark }) => (dark ? "black" : "white")};
  cursor: pointer;
`

const AirmasonNav = ({ isOpen, dark }) => {
  const pathname = getWindowPath()

  return (
    <Nav.Menu isOpen={isOpen}>
      <Link style={{ textDecoration: "none" }} to="/enterprise">
        <MenuText dark={dark}>Enterprise</MenuText>
      </Link>
      <Link style={{ textDecoration: "none" }} to="/plans">
        <MenuText dark={dark}>Pricing</MenuText>
      </Link>
      <Link style={{ textDecoration: "none" }} to="/about">
        <MenuText dark={dark}>About</MenuText>
      </Link>
      {/* {!dark && !["/enterprise", "/contact"].includes(pathname) && (
        <a style={{ textDecoration: "none" }} href="#faq">
          <MenuText dark={dark}>FAQ</MenuText>
        </a>
      )} */}

      {pathname !== "/enterprise" && (
        <a
          style={{ textDecoration: "none" }}
          href={`${config.SUPPORT_CENTER_URL}`}
          target="_black"
          rel="noopener"
        >
          <MenuText dark={dark}>Help</MenuText>
        </a>
      )}
    </Nav.Menu>
  )
}

const Nav = styled.div`
  ${tw`w-full block flex-grow flex flex-col items-center `}
  @media (min-width: ${({ theme }) => theme.screens.md}) {
    ${tw`justify-end flex-row w-auto`}
  }
`

Nav.Menu = styled.div`
  ${tw`flex w-full`}
  ${({ isOpen, theme }) =>
    isOpen ? tw`flex-col items-center pt-4` : tw`hidden`}


  > * {
    ${tw`py-2`}
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    ${tw`flex justify-end flex-row pt-0`}

    > * {
      ${tw`mr-12`}
    }
  }
`

Nav.CTA = styled.div`
  position: fixed;

  bottom: 0;
  left: 0;
  height: 92px;
  width: 100%;

  display: flex;
  align-items: center;

  z-index: 1000;

  ${tw`flex items-center justify-center`}

  background: #eff7ff;

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    background: unset;
    width: unset;
    position: unset;
  }
`

const Header = ({ dark, logo }) => {
  const [isOpen, setIsOpen] = React.useState()
  const toggleIsOpen = React.useCallback(() => setIsOpen(!isOpen))

  return (
    <nav className="flex items-center justify-between flex-wrap py-8">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        {logo ? (
          <Link to="/">{logo}</Link>
        ) : (
          <Link to="/">{dark ? <LogoDark /> : <Logo />}</Link>
        )}
      </div>
      <div className="block md:hidden lg:hidden">
        <button
          onClick={toggleIsOpen}
          className="appearence-none focus:outline-none p-2"
        >
          {dark ? <FaBars /> : <MenuIcon />}
        </button>
      </div>

      <Nav>
        <AirmasonNav dark={dark} isOpen={isOpen} />
        <UserNav />
      </Nav>
    </nav>
  )
}

export default Header
