/* eslint-disable */
import styled from "styled-components"
import React from "react"
import tw from "tailwind.macro"

export const Button = styled.button`
  border-radius: 25px;
  padding: 10px 15px;
  font-weight: bold;
  :focus {
    outline: 0;
  }
`

export const ButtonBlue = styled(Button)`
  background: #1283ff;
  color: white;
  margin-left: 15px;
  padding: 10px 25px;
`

export const ButtonWhite = styled(Button)`
  background: white;
  color: #0f1722;

  padding: 10px 25px;
  font-weight: bold;
  border: solid 1px grey;
`

export const ButtonOutLineBlue = styled(ButtonWhite)`
  padding: 12px 23px;
  border: 1.5px solid #107af1;
  ${tw`cursor-pointer text-sm outline-none`}
`

export const ButtonBlueTextWhite = styled(ButtonWhite)`
  padding: 12px 23px;
  ${tw`text-white cursor-pointer bg-blue-1 text-sm outline-none`}
`

export const BackButton = styled(({ className, onClick }) => (
  <button className={className} onClick={onClick}>
    ⟵ Back to plans
  </button>
))`
  width: 100%;
  border-radius: 25px;
  margin-left: auto;
  padding: 15px 20px;
  outline: none;
  font-size: 18px;
  font-weight: bold;
  color: #1283ff;
  background-color: #f3f4f6;
  margin: 20px auto;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  :focus {
    outline: none;
  }

  :disabled {
    opacity: 0.6;
  }

  @media screen and (min-width: 950px) {
    display: block;
    width: auto;
    float: right;
    margin-right: 50px;
  }
`
