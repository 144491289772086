/*  eslint-disable */
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import WidthWrapper from "src/components/widthWrapper"
import Button from "src/components/button"
import TextStandard from "src/components/textStandard"
import industryCardsPath from "src/images/industryCards.png"
import HideOnMobile from "src/components/hideOnMobile"

const templateTiles = {
  agency: require("./templateTiles/agency.svg"),
  business: require("./templateTiles/business.svg"),
  restaurant: require("./templateTiles/restaurant.svg"),
  retail: require("./templateTiles/retail.svg"),
}

const Section = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 130px;
  // make room for overflowing section below
  @media only screen and (min-width: 992px) {
    padding-top: 130px;
  }
`

const Background = styled.div`
  background-color: #0f1722;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // our mobile max width to keep things centered:
  max-width: 451px;
`

const HeadingBig = styled.div`
  margin-bottom: 35px;
  max-width: 365px;
  font-family: Lato;
  font-style: normal;
  line-height: 40px;
  font-weight: bold;
  font-size: 30px;
  @media only screen and (max-width: 992px) {
    font-size: 26px;
  }

  color: #fff;
`

const ButtonLabel = styled.div`
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 15px;
`

const IndustryButton = styled(Button)`
  background: #1283ff;
  color: #fff;
  padding: 15px 30px;
  font-weight: bold;
`

const TextStandardStyled = styled(TextStandard)`
  color: #8c9bb3;
  max-width: 380px;
  margin-bottom: 35px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`

const TemplateTile = styled(({ src, className, children }) => (
  <div className={className}>
    <div className="label">{children}</div>
    <img src={src} />
  </div>
))`
  position: relative;
  .label {
    position: absolute;
    bottom: 0;
    color: white;
    font-size: 20px;
    margin: 30px;
    font-weight: bold;
  }
`

const TemplateTileGrid = styled(HideOnMobile)`
  margin-bottom: 20px;
  max-width: 390px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > * {
    width: calc(50% - 10px);
    height: 100%;
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 20px;
  }
`

const selectIndustry = () => (
  <Background>
    <Section>
      <WidthWrapper>
        <Row>
          <Column>
            <HeadingBig>
              Pre-made policies created by HR professionals, for HR
              professionals
            </HeadingBig>

            <TextStandardStyled>
              Each template is written by a team of experts. That's why it's the
              best employee handbook software for HR professionals.
            </TextStandardStyled>
            <Link to="/plans">
              <IndustryButton>Try it free</IndustryButton>
            </Link>
          </Column>

          <Column style={{ marginLeft: "auto" }}>
            <TemplateTileGrid>
              <TemplateTile src={templateTiles.agency}>Agency</TemplateTile>
              <TemplateTile src={templateTiles.business}>Business</TemplateTile>
              <TemplateTile src={templateTiles.restaurant}>
                Restaurant
              </TemplateTile>
              <TemplateTile src={templateTiles.retail}>Retail</TemplateTile>
            </TemplateTileGrid>
          </Column>
        </Row>
      </WidthWrapper>
    </Section>
  </Background>
)

export default selectIndustry
