/* eslint-disable */
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"

import HeadingMedium from "../../components/headingMedium"
import HeadingSmall from "../../components/headingSmall"
import WidthWrapper from "../../components/widthWrapper"

import miscFeaturesDesktopBackgroundPath from "../../images/shapes/miscFeaturesDesktopBackground.svg"
import miscFeaturesMobileBackgroundPath from "../../images/shapes/miscFeaturesMobileBackground.svg"

import bookIconPath from "../../images/icons/book.svg"
import groupIconPath from "../../images/icons/group.svg"
import lockIconPath from "../../images/icons/lock.svg"
import paintIconPath from "../../images/icons/paint.svg"
import phoneIconPath from "../../images/icons/phone.svg"
import writeIconPath from "../../images/icons/write.svg"

const Section = styled.div`
  display: flex;
  overflow: visible;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: 30px;

  background-image: url(${miscFeaturesMobileBackgroundPath});
  background-size: 700px 1632px;
  background-repeat: no-repeat;
  background-position: -250px 100px;

  padding-bottom: 115px;
  @media only screen and (min-width: 992px) {
    background-image: none;
    padding-top: 126px;
    margin-top: -20px;
    margin-bottom: 200px;
  }
`

const BackgroundDesktop = styled.img`
  position: absolute;
  z-index: -1;
  margin-left: -463px;
  margin-top: -85px;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`

const BackgroundMobile = styled.img`
  width: 700px;
  height: 1432px;
  margin-left: -250px;
  max-width: none;
  transform: scale(1.2);
  margin-top: 230px;
  position: absolute;
  z-index: -1;
  @media only screen and (min-width: 992px) {
    display: none;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
`

const Row = styled.div`
  ${tw`flex flex-wrap -mx-8 overflow-hidden`}
`
const HeadingMediumStyled = styled(HeadingMedium)`
  max-width: 290px;
`

const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  flex-wrap: wrap;
  @media only screen and (min-width: 992px) {
    margin-top: 8px;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 15px;
  }
`

const Feature = styled.div`
  ${tw`my-8 px-8 overflow-hidden md:w-1/3`} /* display: flex;
  
  align-items: start;
  flex-direction: column; */
`

const FeatureHeading = styled(HeadingSmall)`
  font-family: Lato;
  font-weight: bold;

  margin-bottom: 14px;
  color: #0f1722;
`

const Icon = styled.img`
  margin-bottom: 24px;
  height: 65px;
  object-fit: scale-down;
`

const FeatureText = styled.div`
  max-width: 230px;
  font-family: Lato;
  font-size: 16px;
  line-height: 22px;
  color: #8c9bb3;

  @media only screen and (min-width: 992px) {
    max-width: 230px;
  }
`

const miscFeatures = ({ title, ...props }) => (
  <Section>
    <WidthWrapper>
      <BackgroundDesktop src={miscFeaturesDesktopBackgroundPath} />
      {/* <BackgroundMobile src={miscFeaturesMobileBackgroundPath} /> */}
      <Column>
        {title && <HeadingMediumStyled>{title}</HeadingMediumStyled>}
        <FeatureWrapper>
          <Row>
            <Feature>
              <Icon src={writeIconPath} />
              <FeatureHeading>Digital Signatures</FeatureHeading>
              <FeatureText>
                Require employees to sign your handbook with a legally binding
                electronic signature.
              </FeatureText>
            </Feature>
            <Feature>
              <Icon src={paintIconPath} />
              <FeatureHeading>Custom Design</FeatureHeading>
              <FeatureText>
                100’s of custom fonts, background images, background colours and
                more.
              </FeatureText>
            </Feature>
            <Feature>
              <Icon src={lockIconPath} />
              <FeatureHeading>Password Protected</FeatureHeading>
              <FeatureText>
                Add a password to privatise your handbook, and control who has
                access to it.
              </FeatureText>
            </Feature>
          </Row>
          <Row>
            <Feature>
              <Icon src={groupIconPath} />
              <FeatureHeading>Multiple Collaborators</FeatureHeading>
              <FeatureText>
              Invite team members to help build the handbook. Edits are made in real-time, so you always get the most updated version.
              </FeatureText>
            </Feature>
            <Feature>
              <Icon src={phoneIconPath} />
              <FeatureHeading>Mobile Responsive Design</FeatureHeading>
              <FeatureText>
                Once you publish your handbook, it’s viewable on any computer or
                mobile device.
              </FeatureText>
            </Feature>
            <Feature>
              <Icon src={bookIconPath} />
              <FeatureHeading>Multiple Handbooks</FeatureHeading>
              <FeatureText>
                Have a book for each team? You can manage multiple handbooks in
                one account.
              </FeatureText>
            </Feature>
          </Row>
        </FeatureWrapper>
      </Column>
    </WidthWrapper>
  </Section>
)

miscFeatures.propTypes = {
  title: PropTypes.node,
}

miscFeatures.defaultProps = {
  title: "Everything you need in one easy-to-use platform.",
}

export default miscFeatures
