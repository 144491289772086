/* eslint-disable */
import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

import HeadingBig from "src/components/headingBig"
import WidthWrapper from "src/components/widthWrapper"

const Section = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 992px) {
    margin-bottom: 80px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 770px;
  width: 100%;
  justify-content: space-between;
`

const HeadingBigStyled = styled.h2`
  ${tw`font-sans text-3xl `};
  @media only screen and (max-width: 992px) {
    font-size: 24px;
    line-height: 40px;
  }
  width: 100%;
  font-weight: 300;
  // max-width: 750px;
  font-size: 30px;

  color: #0f1722;
`

const LogoWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  > * {
    width: calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 992px) {
    margin-left: -20px;
    margin-top: 59px;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 55px;
  }
`

const Logo = styled.img`
  object-fit: contain;
  height: 100%;
  @media only screen and (min-width: 992px) {
    max-width: 170px;
    margin-right: 20px;
  }
`

const LogoMobileColumn = styled.div`
  display: flex;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const HeadingStyled = styled.h3`
  ${tw`font-sans text-3xl my-4`};
  @media only screen and (max-width: 992px) {
    font-size: 24px;
    line-height: 40px;
  }
  width: 100%;
  font-weight: 400;
  color: #0f1722;
`

const partners = () => (
  <Section>
    <WidthWrapper>
      <Column>
        <HeadingStyled>The Leading Handbook Maker</HeadingStyled>
        <HeadingBigStyled>
          Join over 6000 industry leaders from around the world who trust
          AirMason for their online employee handbook generator.
        </HeadingBigStyled>
        <LogoWrapper>
          <Logo src={require("./images/hinge.png")} />
          <Logo src={require("./images/kinaxis.png")} />
          <Logo src={require("./images/daggerwing.png")} />
          <Logo src={require("./images/looka.png")} />
        </LogoWrapper>
      </Column>
    </WidthWrapper>
  </Section>
)

export default partners
