import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react"
import ModalStyle from "./style"
import closeIcon from "src/images/close_modal.svg"

export interface ModalProps {
  id?: string
  isOpen?: boolean
  children?: any
  className?: any
  onClose?: any
  ref?: any
}

let modalResult = false

function onEscKeyDown(e: any, setState: any): void {
  if (e.key !== "Escape") return
  setState({ fadeType: "out" })
}

function closeModal(e: any, setState: any): void {
  e.preventDefault()
  setState({ fadeType: "out" })
}

function transitionEnd(props: any, state: any): void {
  if (state.fadeType === "in") {
    modalResult = false
    document.body.classList.add("overflow-hidden")
    return
  }

  if (state.fadeType === "out") {
    document.body.classList.remove("overflow-hidden")
    props.onClose && props.onClose(modalResult)
  }
}

const Modal: React.FC<ModalProps> = forwardRef(
  ({ children, id, className, ...props }, ref) => {
    const [state, setState] = useState({ fadeType: "" })
    const onEscKeyDownHandler = (e: any) => onEscKeyDown(e, setState)
    id = id || "modal-" + new Date().getTime()

    useEffect(() => {
      window.addEventListener("keydown", onEscKeyDownHandler, false)

      if (props.isOpen) {
        setState({ fadeType: "in" })
      } else {
        setState({ fadeType: "out" })
      }

      return () => {
        window.removeEventListener("keydown", onEscKeyDownHandler, false)
      }
    }, [props.isOpen])

    useImperativeHandle(ref, () => ({
      close: (result?: boolean) => {
        modalResult = result || false
        setState({ fadeType: "out" })
      },
    }))

    return (
      <ModalStyle
        id={id}
        className={`wrapper fade-${state.fadeType} ${className || ""}`}
        role="dialog"
        onTransitionEnd={() => transitionEnd(props, state)}
      >
        <div className="box-dialog">
          <button onClick={e => closeModal(e, setState)} className="close">
            <img src={closeIcon} />
          </button>
          <div className="box-content">{children}</div>
        </div>
        <div
          className={`background`}
          onMouseDown={e => closeModal(e, setState)}
        />
      </ModalStyle>
    )
  }
)

export default Modal
