import styled from "styled-components"

export default styled.div`
  margin: 0 auto;
  @media only screen and (min-width: 992px) {
    max-width: ${props => (props.isHomePage ? "1185px" : "951px")};
    padding: ${props => (props.isHomePage ? "0 15px" : "0px")};
    width: 100%;
  }
  @media only screen and (max-width: 992px) {
    padding: ${props => (props.isHomePage ? "0 15px" : "0 27px")};
    max-width: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
`
