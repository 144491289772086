import styled, { StyledComponent } from "@emotion/styled"
import tw from "tailwind.macro"

const TextArea: StyledComponent<any, any, any> = styled.textarea`
  ${tw`
    font-body
    appearance-none
    block
    antialiased
    text-base
    border
    border-gray-16
    rounded
    w-full
    px-3
    py-2
    font-light
    text-black
    leading-tight
    resize-none
    min-h-32
    focus:outline-none
    focus:shadow
    shadow-input
    h-32
`}
  &::placeholder {
    opacity: 1;
    ${tw`text-gray-1`}
  }
`

export default TextArea
