/* eslint-disable */
/* to-do:
1. add a space between the toggle icon and the question
2. collopase some of the FAQ 
*/

import React from "react"
import styled from "styled-components"
import constant from "src/utils/constant"

import WidthWrapper from "../../components/widthWrapper"
import HeadingSmall from "../../components/headingSmall"
import Accordion from "../../components/accordion"

import speechIconPath from "../../images/icons/speech.svg"

const Section = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-bottom: 70px;
`
//  alignment on desktop is broken
const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  // our mobile max width to keep things centered
  @media only screen and (max-width: 992px) {
    max-width: 451px;
  }
  max-width: 581px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 451px;
`

const IconText = styled(HeadingSmall)`
  font-family: Lato;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  color: #0f1722;
`

const Icon = styled.img`
  margin-right: 21px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 27px;
  }
`

const accordionItems = [
  {
    question: "Can I try it before I buy it?",
    answer: `Yes! AirMason offers a free ${constant.trialDays}-day trial for all new sign-ups.`,
  },
  {
    question: "What if I sign up using a paid plan, but don't like it?",
    answer:
      `If you're not satisfied after your first ${constant.trialDays} days, we have a 100% money-back guarantee.`,
  },
  {
    question: "Do I lose my handbook once my trial ends?",
    answer:
      "We keep your handbook for 120 days after your trial ends and it can be accessed by signing up for one of our plans.",
  },
  {
    question: "How do I cancel my trial or plan?",
    answer:
      "To cancel, simply send us an email at hello@airmason.com or message us through our chat bubble at www.airmason.com.",
  },
  {
    question: "How many handbooks can I create?",
    answer:
      "AirMason employee handbook app offers limited handbooks under Seedling and Start Up plans, however our Small Biz and Enterprise plans offer unlimited handbooks.",
  },
  {
    question: "Can you convert our existing handbook to an AirMason handbook?",
    answer:
      "Yes, our team can import and design your existing handbook as part of our Small Biz and Enterprise plans.",
  },
  {
    question: "Can I make changes to the handbook anytime?",
    answer:
      "You can edit your content at any time and changes occur as soon as you re-publish your handbook.",
  },
  {
    question: "Can I integrate this into our company portal?",
    answer: "Yes, you can embed your AirMason handbook in your company portal.",
  },
  {
    question: "Can I export my handbook as a PDF?",
    answer:
      "Of course! Our online employee handbook maker allows you to share your published handbook as a mobile-friendly web-page or a print ready as a PDF.",
  },
  {
    question:
      "How does billing work if our company goes from 300 - 500 employees in less than a year?",
    answer:
      "AirMason will honour the original cost for 300 employees until the end of the billing cycle. Once the cycle ends, we will increase your license to 500 employees.",
  },
  {
    question:
      "If I have multiple handbooks, can I appoint one person to set up specific access levels for all handbooks?",
    answer:
      "We are currently building this functionality, but in the meantime, this can be set up as a custom feature.",
  },
  {
    question: "Can I integrate AirMason into my current HRIS system?",
    answer:
      "Yes! To learn more about how custom integrations work and how much they cost, send a message to davejot@airmason.com.",
  },
  {
    question: "Can enterprise clients request custom requirements?",
    answer:
      "Absolutely. In fact, AirMason's creative team will also review and proofread your content.",
  },
  {
    question: "What enterprise compliances do you have in place?",
    answer: "AirMason uses HIPPA Compliant, PCI Compliance and GDPR (Optional)",
  },
]

const faq = () => (
  <Section id="faq">
    <WidthWrapper>
      <Column>
        <IconWrapper>
          <Icon src={speechIconPath} />
          <IconText>Frequently Asked Questions</IconText>
        </IconWrapper>
        <Accordion items={accordionItems} />
      </Column>
    </WidthWrapper>
  </Section>
)

export default faq
