import { Link } from "gatsby"
/* eslint-disable */

import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

import Button from "../../components/button"
import Header from "../../components/ui/Header"
import WidthWrapper from "../../components/widthWrapper"
import headerBackgroundPath from "../../images/headerBackground.svg"
import heroVideoPath from "../../videos/hero.mp4"
import heroPosterPath from "../../videos/posters/hero.png"
import config from "../../config"

const Section = styled.div`
  @media only screen and (min-width: 992px) {
    .margin-logo {
      margin-bottom: 50px;
    }
  }
  @media only screen and (max-width: 992px) {
    .margin-logo {
      margin-bottom: 54px;
    }
    .mobile-hide {
      display: none;
    }
  }
  height: auto;
  background: #0f1722;
  width: 100%;
`

const HeadingBig = styled.h3`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  color: #fff;
  font-size: 26px;

  @media only screen and (min-width: 992px) {
    line-height: 36px;
  }
  @media only screen and (max-width: 992px) {
    line-height: 31px;
  }
`

const BackgroundBlack = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  background: url(${headerBackgroundPath});
`

const Description = styled.div`
  font-size: 20px;
  margin-bottom: 24px;
  @media only screen and (min-width: 992px) {
    max-width: 263px;
    margin-bottom: 30px;
  }
`

const ColumnLeft = styled.div`
  max-width: 292px;

  @media only screen and (min-width: 992px) {
    height: 360px;
  }
`

const Video = styled.video`
  @media only screen and (min-width: 992px) {
    max-width: 629px;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: -100px;
    max-width: 446px;
    width: 100%;
  }
  max-height: 379px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.190969);
`

const NavigationContainer = styled.div`
  @media only screen and (min-width: 992px) {
    margin: 45px 0 65px;
    height: 35px;
  }
  @media only screen and (max-width: 992px) {
    order: 2;
  }
  color: white;
`

const Logo = styled.img`
  ${tw`hidden`}
  @media (min-width: ${({ theme }) => theme.screens.md}) {
    ${tw`block self-start mb-12`}
  }
`

const ActionButton = styled(Button)`
  height: 50px;
  padding: 15px 30px;
`

const Hero = () => (
  <Section>
    <BackgroundBlack>
      <WidthWrapper>
        <Header />
        <div>
          <div style={{ color: "red", float: "right" }}>
            <a
              href={`${config.HANDBOOK_HOST}/books/valdez`}
              className="mb-4 text-white no-underline visited:color-white"
            >
              See a handbook in action ⟶
            </a>
          </div>

          <div
            style={{ paddingBottom: "65px", paddingTop: "20px" }}
            className="flex-grow"
          >
            <div className="flex flex-col justify-center lg:justify-between lg:flex-row flex-grow lg:items-start mt-4">
              <ColumnLeft className="flex flex-col lg:justify-between flex-grow">
                {/* <Logo src={logoPath} /> */}
                <HeadingBig>
                  Bring your employee handbook to the 21st Century.
                  <br />
                </HeadingBig>
                <Description className="text-grey leading-normal lg:my-8">
                  Easily create, distribute, track and update your handbooks —
                  anytime, anywhere.
                </Description>
                <Link to="/plans">
                  <ActionButton className="bg-blue font-bold text-white mobile-hide">
                    Start Your Trial
                  </ActionButton>
                </Link>
              </ColumnLeft>
              <div className="flex flex-col items-end">
                <Video
                  className="lg:pl-20 w-full outline-none"
                  src={heroVideoPath + "#t=2"}
                  poster={heroPosterPath}
                  preload="none"
                  autoPlay={false}
                  loop
                  controls
                  muted
                />
              </div>
            </div>
          </div>
        </div>
      </WidthWrapper>
    </BackgroundBlack>
  </Section>
)

export default Hero
