import styled from "styled-components"

interface LayoutProps {
  isBackgroundWhite?: boolean
  isWidthFull?: boolean
}

interface LayoutWrapperProps {
  isWidthFull?: boolean
}

export const ContainerPage = styled.div<LayoutProps>`
  background-color: ${props => (props.isBackgroundWhite ? "white" : "#F5F8FB")};
  padding: ${props => (props.isWidthFull ? "80px 0 45px;" : "80px 15px 45px;")};

  @media (min-width: 768px) {
    padding: ${props => (props.isWidthFull ? "80px 0;" : "80px 15px;")};
  }
`
export const WrapperPage = styled.div<LayoutWrapperProps>`
  max-width: ${props => (props.isWidthFull ? "100%" : "1185px")};
  margin: 0 auto;
`

export const Row = styled.div`
  flex-wrap: wrap;
  text-align: center;
  @media (min-width: 768px) {
    display: flex;
  }
`

export const Col = styled.div`
  min-width: 50%;
`
